@import "./shared.scss";

.letter {
  @include header-font;
  background-color: transparent;
  color: white;
  font-size: 2.5rem;
  line-height: 6rem;
  padding: 0px;
  border: none;
  flex: 1;
  transition: all 0.3s ease;
  outline-width: 0;
  cursor: pointer;

  &:hover {
    color: white;
    animation-duration: 0.5s;
    animation-name: hovered;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &.selected {
    z-index: 1;
    text-shadow: 0px 0px 5px rgb(172, 123, 233);
    font-size: 3rem;
  }

  @media (min-width: $min-width) {
    font-size: 3rem;
    &.unselected {
      animation-duration: 0.5s;
      animation-name: unselectIt;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
    &.selected {
      font-size: 6rem;
      animation-duration: 0.5s;
      animation-name: selectIt;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      transform: translateY(5px);
    }
  }
}

@keyframes hovered {
  0% {
    color: white;
  }
  25% {
    color: $orange;
  }
  50% {
    color: $blue;
  }
  75% {
    color: $green;
  }
  100% {
    color: white;
  }
}

@keyframes selectIt {
  0% {
    font-size: 3rem;
  }

  70% {
    transform: translateY(35px);
  }

  100% {
    transform: translateY(5px);
    font-size: 6rem;
  }
}

@keyframes unselectIt {
  0% {
    transform: translateY(5px);
    font-size: 6rem;
  }

  30% {
    transform: translateY(25px);
  }

  100% {
    font-size: 3rem;
  }
}
