@import "./shared.scss";

section {
  @include copy-font;
  background-color: rgba(black, 0.8);
  color: white;
  padding: 40px;
  height: calc(100vh - 6rem);

  ul {
    list-style: none;
    padding: 0;
    border-left: 1px solid rgba(white, 0.5);

    li {
      margin-top: $spacing-small;
      padding-left: $spacing-mid;
      font-weight: bold;
    }
  }

  a {
    color: white;
    transition: all 0.2s ease;
    margin-top: $spacing-small;

    &:hover {
      background-color: white;
      color: gray;
    }
  }

  &.sectionFading {
    animation-duration: 0.5s;
    animation-name: fade;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }

  &.sectionEnter {
    animation-duration: 0.5s;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
}

.contentContainer {
  @media (min-width: $min-width-mid) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
}
// have everything slide down and settle into place on transition.

@keyframes fade {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
