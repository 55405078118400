$orange: orange;
$blue: blue;
$green: green;

$min-width: 415px;
$min-width-mid: 769px;

$spacing-small: 10px;
$spacing-mid: 20px;
$spacing-large: 40px;

@mixin header-font {
  font-family: "Odibee Sans", cursive;
}
@mixin copy-font {
  font-family: "IBM Plex Mono", monospace;
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  70% {
    transform: translateY(35px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
