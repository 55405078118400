@import "./scss/header.scss";
@import "./scss/letters.scss";
@import "./scss/section.scss";
@import "./scss/box.scss";
@import "./scss/footer.scss";
@import "./scss/shared.scss";

html,
body {
  background-color: rgba(0, 0, 0, 0.8);
}

.page {
  background-image: url("./images/synthwave-3941721.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100vw;
  animation-duration: 0.5s;
  animation-name: fadeInGrow;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;

  &.transitioning {
    animation-duration: 0.5s;
    animation-name: outIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
}

@keyframes fadeInGrow {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes outIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}
