@import "./shared.scss";

footer {
  @include copy-font;
  align-items: stretch;
  position: relative;
  bottom: 0px;
  height: 80px;
  width: 100vw;
  padding: 10px;
  color: white;

  nav {
    a {
      color: white;
      padding: 0 10px;
      transition: all 0.3 ease;
      i {
        padding: 20px;
      }

      :hover {
        text-shadow: 2px 2px 2px rgba(white, 0.3);
        color: rgb(186, 153, 153);
      }
    }
  }

  p {
    margin: 0;
    line-height: 0;
    padding: 10px;
  }

  @media (min-width: $min-width-mid) {
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 40px;
    nav,
    p {
      flex: 1;
    }
    p {
      padding: 0px;
      text-align: right;
    }
  }
}
