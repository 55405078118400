* {
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100vw;
}
