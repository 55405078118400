@import "./shared.scss";

.box {
  padding-right: 2%;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-top: 1px solid $green;
  margin-top: $spacing-mid;
  // TODO:: Needs work
  .box-image {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    opacity: 0.5;

    img {
      width: 80px;
      height: 80px;
      display: block;
      border-radius: 50%;
    }
  }

  @media (min-width: $min-width-mid) {
    flex: 1 0 21%;
    border-top: 0px;
    padding: 0px $spacing-small;
  }
}

section {
  @include copy-font;
  background-color: rgba(black, 0.8);
  color: white;
  padding: 40px;
  height: calc(100vh - 6rem);

  h2 {
    animation-duration: 0.5s;
    animation-name: fadeInFromTop;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }

  &.sectionFading {
    animation-duration: 0.5s;
    animation-name: fade;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }

  &.sectionEnter {
    animation-duration: 0.5s;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }
}

// have everything slide down and settle into place on transition.

@keyframes fade {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
